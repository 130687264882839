import React from 'react';
import { graphql } from 'gatsby';

import Layout from 'components/Layout';
import SEO from 'components/SEO';
import ErrorPage from 'components/ErrorPage';

const NotAccessPage = ({ data }: any) => {
  const {
    title,
    description,
    subtitle,
    goBack,
    seoTitle,
  } = data.wpPage.pageContent;

  return (
    <Layout>
      <SEO title={seoTitle} />
      <ErrorPage
        error={title}
        title={subtitle}
        text={description}
        goBack={goBack}
      />
    </Layout>
  );
};

export default NotAccessPage;

export const query = graphql`
  query {
    wpPage(title: { in: "403" }) {
      pageContent {
        description
        goBack
        subtitle
        title
        seoTitle
      }
    }
  }
`;
